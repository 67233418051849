<template>
  <div>
    <el-card>
      <el-form ref="mainForm" :model="mainForm.data" :rules="verifyRules.buddyManagerAddressTypeAdd.mainForm" label-width="130px">
        <el-row :gutter="12">
          <el-col :md="24" :lg="12">
            <!-- 中文名 -->
            <el-form-item :label="$t('text.cnName')" prop="name">
              <el-input
                v-model="mainForm.data.name"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="12">
            <!-- 英文名 -->
            <el-form-item :label="$t('text.enName')" prop="name_en">
              <el-input
                v-model="mainForm.data.name_en"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row type="flex" justify="center">
          <el-button @click="handleMainFormCancelClick">{{ $t('button.cancel') }}</el-button>
          <el-button type="primary" @click="handleMainFormSubmitClick">{{ $t('button.confirm') }}</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
