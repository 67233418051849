/**
 * 团长地址类型相关接口
 */
import request from '@/utils/request'

export const getAddressTypeList = () => {
  return request({
    method: 'GET',
    url: '/cms/address_types/address_type_list'
  })
}

export const getSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/address_types/setup'
  })
}

export const getAddressTypes = (params) => {
  return request({
    method: 'GET',
    url: '/cms/address_types',
    params
  })
}

export const getIdPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/address_types/${id}/preview`
  })
}

export const getAddressTypesId = (id) => {
  return request({
    method: 'GET',
    url: `/cms/address_types/${id}`
  })
}

export const putAddressTypesId = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/address_types/${id}`,
    data
  })
}

export const postAddressTypes = (data) => {
  return request({
    method: 'POST',
    url: '/cms/address_types',
    data
  })
}

export const deleteAddressTypesId = (id, data) => {
  return request({
    method: 'DELETE',
    url: `/cms/address_types/${id}`,
    data
  })
}
